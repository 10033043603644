import { Resource } from 'react-admin'
import { HydraAdmin } from '@api-platform/admin'
import { CustomerListAction } from './actions/Customer/list'
import { CustomerEditAction } from './actions/Customer/edit'
import { InvalidCustomerListAction } from './actions/InvalidCustomer/list'
import CustomDataProvider from './dataProvider/CustomDataProvider'
import useAuthentication from './authProvider/AuthProvider'

export const getApiUrl = (): string => {
  if (process.env.REACT_APP_CUSTOMER_ACCOUNT_API_URL) {
    return process.env.REACT_APP_CUSTOMER_ACCOUNT_API_URL
  }

  return 'CUSTOMER_ACCOUNT_API_URL_PLACEHOLDER'
}

const App = () => {
  const customDataProvider = CustomDataProvider(getApiUrl())
  const { authProvider, checkUserPermission, checkAuthentication } = useAuthentication()

  checkAuthentication()

  return (
    <HydraAdmin
      authProvider={authProvider}
      loginPage={false}
      dataProvider={customDataProvider}
      entrypoint={getApiUrl()}
    >
      {async () => [
        (await checkUserPermission('customers-get')) ? (
          <Resource name={'customers'} list={CustomerListAction} edit={CustomerEditAction} />
        ) : null,
        (await checkUserPermission('admin-read')) ? (
          <Resource name={'invalid-customers'} list={InvalidCustomerListAction} />
        ) : null,
      ]}
    </HydraAdmin>
  )
}

export default App
