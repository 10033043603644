import { List, Datagrid, TextField, DeleteButton, FunctionField } from 'react-admin'
import { useEffect, useState } from 'react'
import { fetchSvlMappings } from '../../utils/Utils'

export const InvalidCustomerListAction = (props: object) => {
  const [svlMappings, setSvlMappings] = useState<Record<string, Record<string, string>>>({})

  useEffect(() => {
    ;(async () => {
      const mappings = await fetchSvlMappings()
      setSvlMappings(mappings)
    })()
  }, [])

  if (!Object.keys(svlMappings).length) return null

  return (
    <List {...props}>
      <Datagrid>
        <TextField source="recordId" label="ID" />
        <TextField source="customerId" />
        <TextField source="customerName" />
        <TextField source="tin" />
        <TextField source="bin" />
        <FunctionField
          source="status"
          label="Status"
          render={(record: any) =>
            svlMappings['Status'] && record.status
              ? svlMappings['Status'][record.status] || record.status
              : record.status
          }
        />
        <DeleteButton />
      </Datagrid>
    </List>
  )
}
