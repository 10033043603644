import { CognitoAuthProvider } from 'ra-auth-cognito'
import { jwtDecode } from 'jwt-decode'

export interface WithScopeJwtPayload {
  scope: string
}
const useAuthentication = () => {
  const authProvider = CognitoAuthProvider({
    mode: 'oauth',
    clientId: process.env.REACT_APP_CLIENT_ID ? process.env.REACT_APP_CLIENT_ID : 'CLIENT_ID_PLACEHOLDER',
    userPoolId: process.env.REACT_APP_USER_POOL_ID ? process.env.REACT_APP_USER_POOL_ID : 'USER_POOL_ID_PLACEHOLDER',
    redirect_uri: window.location.origin,
    hostedUIUrl: process.env.REACT_APP_USER_POOL_ID
      ? process.env.REACT_APP_ADMIN_HOSTED_UI_URL
      : 'ADMIN_HOSTED_UI_URL_PLACEHOLDER',
    scope: ['openid', 'aws.cognito.signin.user.admin'],
  })

  const checkAuthentication = async () => {
    console.log('checkingAuth', authProvider)

    if (window.location.href.includes('access_token')) {
      handleCallback()
    }

    if (authProvider.checkAuth) {
      authProvider.checkAuth({})
    }
  }

  const handleCallback = () => {
    console.log('handleCallback')

    if (authProvider.handleCallback) {
      console.log('handling callback', authProvider)
      authProvider.handleCallback().then(() => {
        console.log('callback handled.. redirecting')
        return window.location.replace('/')
      })
    }
  }

  const getAccessToken = async () => {
    if (!authProvider.getIdentity) {
      throw new Error('User is not authenticated')
    }

    console.log('getting access token')
    const identity = await authProvider.getIdentity()

    console.log('identity', identity)

    if (identity === undefined) {
      throw new Error('Identity is not provided')
    }

    return identity.cognitoUser.signInUserSession.getAccessToken().getJwtToken()
  }

  const checkUserPermission = async (...checkedScope: string[]) => {
    console.log('checking permission for', checkedScope)
    const accToken = await getAccessToken()
    const decodedToken = jwtDecode(accToken) as WithScopeJwtPayload
    const tokenScopes = decodedToken.scope.split(' ').map((scope) => scope.substring(scope.lastIndexOf('/') + 1))
    return checkedScope.every((scope) => tokenScopes.includes(scope))
  }

  return { authProvider, getAccessToken, checkUserPermission, checkAuthentication }
}

export default useAuthentication
