import { getApiUrl } from '../App'
import customDataProvider from '../dataProvider/CustomDataProvider'

const SVL_MAPPINGS_CACHE_KEY = 'svlMappings'
const CACHE_TTL = 24 * 60 * 60 * 1000

export const fetchSvlMappings = async (): Promise<Record<string, Record<string, string>>> => {
  const cachedData = localStorage.getItem(SVL_MAPPINGS_CACHE_KEY)
  const cachedTimestamp = localStorage.getItem(`${SVL_MAPPINGS_CACHE_KEY}_timestamp`)

  if (cachedData && cachedTimestamp && Date.now() - parseInt(cachedTimestamp, 10) < CACHE_TTL) {
    console.log('Using cached SVL mappings')
    return JSON.parse(cachedData)
  }

  console.log('Fetching SVL mappings from API')
  try {
    const svlResponse = await customDataProvider(getApiUrl()).getList('svl', {})

    const mappings = svlResponse.data.reduce((acc: any, item: any) => {
      acc[item.name] = item.values
      return acc
    }, {})

    localStorage.setItem(SVL_MAPPINGS_CACHE_KEY, JSON.stringify(mappings))
    localStorage.setItem(`${SVL_MAPPINGS_CACHE_KEY}_timestamp`, Date.now().toString())

    return mappings
  } catch (error) {
    console.error('Error fetching SVL mappings:', error)
    throw new Error('Unable to fetch SVL mappings')
  }
}
