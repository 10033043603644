import { List, Datagrid, TextField, TextInput, DateField, DateInput, Filter, FunctionField } from 'react-admin'
import { fetchSvlMappings } from '../../utils/Utils'
import React, { useEffect, useState } from 'react'
import SvlInput from '../../components/SvlInput'

const CustomerFilter = ({ svlMappings }: { svlMappings: Record<string, Record<string, string>> }) => (
  <Filter>
    <TextInput label="CA ID" source="id" defaultValue="" alwaysOn />
    <SvlInput
      source="affiliatePartner"
      label="Affiliate Partner"
      svlMappings={svlMappings}
      svlMappingKey="AffiliatePartner"
      alwaysOn
    />
    <TextInput label="BIN" source="bin" alwaysOn />
    <TextInput label="TIN" source="tin" alwaysOn />
    <TextInput label="VAT_ID" source="vatId" alwaysOn />
    <TextInput label="Customer name" source="customerName" alwaysOn />
    <TextInput label="Customer short name" source="shortName" alwaysOn />
    <DateInput label="Created date" source="created" alwaysOn />
    <DateInput label="Last change date" source="updated" alwaysOn />
  </Filter>
)

export const CustomerListAction = (props: object) => {
  const [svlMappings, setSvlMappings] = useState<Record<string, Record<string, string>>>({})

  useEffect(() => {
    ;(async () => {
      const mappings = await fetchSvlMappings()
      setSvlMappings(mappings)
    })()
  }, [])

  if (!Object.keys(svlMappings).length) return null

  return (
    <List filters={CustomerFilter({ svlMappings })} {...props}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="originId" label="Id" />
        <TextField source="customerName" />
        <TextField source="shortName" />
        <TextField source="tin" />
        <TextField source="bin" />
        <TextField source="vatId" label="VAT ID" />
        <FunctionField
          source="status"
          label="Status"
          render={(record: any) =>
            svlMappings['Status'] && record.status
              ? svlMappings['Status'][record.status] || record.status
              : record.status
          }
        />
        <FunctionField
          source="affiliatePartner"
          label="Affiliate Partner"
          render={(record: any) =>
            svlMappings['AffiliatePartner'] && record.affiliatePartner
              ? svlMappings['AffiliatePartner'][record.affiliatePartner] || record.affiliatePartner
              : record.affiliatePartner
          }
        />
        <DateField source="created" showTime={true} locales="cs-CZ" />
        <DateField source="updated" showTime={true} locales="cs-CZ" />
      </Datagrid>
    </List>
  )
}
