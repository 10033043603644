import React, { FC } from 'react'
import { SelectInput, NumberInput } from 'react-admin'

interface SvlSelectInputProps {
  source: string
  label: string
  svlMappings: Record<string, Record<string, string>>
  svlMappingKey: string
  [key: string]: any
}

const SvlSelectInput: FC<SvlSelectInputProps> = ({ source, label, svlMappings, svlMappingKey, ...props }) => {
  const choices = svlMappings[svlMappingKey]
    ? Object.entries(svlMappings[svlMappingKey]).map(([id, name]) => ({
        id: parseInt(id, 10),
        name,
      }))
    : []

  return choices.length ? (
    <SelectInput source={source} label={label} choices={choices} {...props} />
  ) : (
    <NumberInput source={source} label={label} {...props} />
  )
}

export default SvlSelectInput
